import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/base.js";
import HomeHero from "@components/home-hero";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HomeHero mdxType="HomeHero" />
    <div className="container">
      <section className="references">
        <h2>{`References:`}</h2>
        <ol>
          <li parentName="ol">{`Lyman GH. Impact of chemotherapy dose intensity on cancer patient outcomes. `}<em parentName="li">{`J Natl Compr Canc Netw.`}</em>{` 2009;7(1):99-108.`}</li>
          <li parentName="ol">{`Lyman GH, Dale DC, Friedberg J, Crawford J, Fisher RI. Incidence and predictors of low chemotherapy dose-intensity in aggressive non-Hodgkin’s lymphoma: a nationwide study. `}<em parentName="li">{`J Clin Oncol.`}</em>{` 2004;22(21):4302-4311.`}</li>
          <li parentName="ol">{`Denduluri N, Patt DA, Wang Y, et al. Dose delays, dose reductions, and relative dose intensity in patients with cancer who received adjuvant or neoadjuvant chemotherapy in community oncology practices. `}<em parentName="li">{`J Natl Compr Canc Netw.`}</em>{` 2015;13(11):1383-1393.`}</li>
          <li parentName="ol">{`Schwartzberg LS, Bhat G, Peguero J, et al. Eflapegrastim, a long-acting granulocyte-colony stimulating factor for the management of chemotherapy-induced neutropenia: results of a phase III trial. `}<em parentName="li">{`Oncologist.`}</em>{` 2020;25(8):e1233-e1241.`}</li>
          <li parentName="ol">{`ROLVEDON `}{`[package insert]`}{`. Lake Forest, IL: Spectrum Pharmaceuticals, Inc.`}</li>
          <li parentName="ol">{`Cobb PW, Moon YW, Mezei K, et al. A comparison of eflapegrastim to pegfilgrastim in the management of chemotherapy-induced neutropenia in patients with early-stage breast cancer undergoing cytotoxic chemotherapy (RECOVER): a phase 3 study. `}<em parentName="li">{`Cancer Med.`}</em>{` 2020;9(17):6234–6243.`}</li>
          <li parentName="ol">{`Data on file. Spectrum Pharmaceuticals, Inc.`}</li>
        </ol>
      </section>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      